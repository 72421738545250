import React from "react";
import * as styles from "./PageMargins.module.scss";
import classNames from "classnames";

type PageMarginsProps = {
	children: React.ReactNode;
	fullWidth?: boolean;
    widthOnLargeScreensClass?: string;
	className?: string;
};

export default function PageMargins({
	children,
	fullWidth = true,
    widthOnLargeScreensClass,
}: PageMarginsProps) {
	return (
		<div className={styles.center}>
			<div
				className={classNames(styles.limitWidthOnLargeScreens, widthOnLargeScreensClass)}
				style={{ width: fullWidth ? "100%" : "unset" }}
			>
				{children}
			</div>
		</div>
	);
}
