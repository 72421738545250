// extracted by mini-css-extract-plugin
export var backgroundImage = "BackgroundFadeContainer-module--backgroundImage--kXYZK";
export var blueFade = "BackgroundFadeContainer-module--blueFade--MCY0m";
export var darkBlue = "BackgroundFadeContainer-module--darkBlue--Ykul8";
export var dpaBlue = "BackgroundFadeContainer-module--dpaBlue--VhOjT";
export var fade = "BackgroundFadeContainer-module--fade--EkeGo";
export var featureCardGray = "BackgroundFadeContainer-module--featureCardGray--GRgoP";
export var flexColumnCenter = "BackgroundFadeContainer-module--flexColumnCenter--1CtPQ";
export var fullWidth = "BackgroundFadeContainer-module--fullWidth--JUj81";
export var limitWidthOnLargeScreens = "BackgroundFadeContainer-module--limitWidthOnLargeScreens--ICQGh";
export var pinkFade = "BackgroundFadeContainer-module--pinkFade--2y50c";
export var purpleBlueFade = "BackgroundFadeContainer-module--purpleBlueFade--w4HsQ";
export var skewBottom = "BackgroundFadeContainer-module--skewBottom--fPjcd";
export var skewTop = "BackgroundFadeContainer-module--skewTop--Z+8aU";
export var solidBlue = "BackgroundFadeContainer-module--solidBlue--IrhNZ";
export var solidLightPink = "BackgroundFadeContainer-module--solidLightPink--5PFBw";
export var white = "BackgroundFadeContainer-module--white--Fs+Xu";
export var withCustomHeaderPadding = "BackgroundFadeContainer-module--withCustomHeaderPadding--pbyO0";
export var withHeaderPadding = "BackgroundFadeContainer-module--withHeaderPadding--Jo54k";